:root {
  --blue: rgb(191, 231, 246);
  --yellow: rgb(255, 230, 102);
  --orange: rgb(245, 194, 125);
  --pink: rgb(227, 183, 210);
  --orangepink: rgb(246, 206, 191);
}

html {
  min-width: 300px;
  background-color: var(--blue);
}

* {
  font-family: "Righteous", cursive;
}

.lineThrough {
  text-decoration: line-through;
}
.statusHeader {
  text-align: center;
}
.editHeader {
  text-align: center;
}

.Todo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.todoTable {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1.5em;
}

table,
td,
th {
  border-bottom: 1px solid;
}
.nothingText {
  text-align: center;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.formDiv {
  min-width: 300px;
  width: 100%;
  max-width: 600px;
}
form {
  display: flex;
  justify-content: space-between;
}
textarea {
  width: 100% !important;
  min-height: 1.5em;
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 2em;
  border-radius: 5px;
  margin-right: 0.25em;
  margin-left: 0.25em;
}

td {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
tr {
  width: 600px;
}
#testing {
  word-wrap: break-word;
  width: 400px;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 350px;
}
.buttonContainer button {
  width: 8em;
}
.buttonContainer input {
  width: 8em;
}

button {
  border-radius: 5px;
  border: 1px solid grey;
}

#reset-btn {
  background-color: var(--pink);
}

#download-btn {
  background-color: var(--yellow);
}

#import-btn {
  background-color: var(--orange);
}
#add-btn {
  background-color: var(--orangepink);
  margin-right: 0.25em;
}
.delete-btn {
  background-color: var(--pink);
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.delete-btn,
#reset-btn,
#download-btn,
#import-btn {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
